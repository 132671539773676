import { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    useMediaQuery,
    InputAdornment,
    Button,
    Box,
    Card,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import BasicBreadcrumbs from 'components/cards/BreadScrumbs';
import CustomTextInput from 'components/inputs/TextInput';
import CustomButton from 'components/extended/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableLoadingSkeleton from 'skeleton/TablePlaceholder';
import { BusinessOutlined, DescriptionOutlined, LocationCityOutlined } from '@mui/icons-material';
import { getAllSuppliers, getOneSupplier, updateSupplier } from 'store/suppliers';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from '@mui/icons-material/Edit';
import profileIcon from '../../../assets/images/empty-image.png';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import nofile from '../../../assets/images/noFiles.png';
import CustomDeleteModal from 'components/modal/DeleteModal';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const EditSupplierPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [supplierData, setSupplierData] = useState({});
    const supplierResponse = useSelector((state) => state.supplierService.getOne);
    const navigate = useNavigate();
    const params = useParams();
    const supplierId = params.id;
    const [supplierImage, setSupplierImage] = useState();
    const [supplierImageURL, setSupplierImageURL] = useState(null);
    const [fileToDeleteIndex, setFileToDeleteIndex] = useState(null);
    const [fileId, setFileId] = useState(null);
    const [documentsList, setDocumentsList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (supplierId) {
            dispatch(getOneSupplier(supplierId));
        }
    }, [supplierId, dispatch]);

    useEffect(() => {
        if (supplierResponse) {
            switch (true) {
                case supplierResponse.loading:
                    setLoading(true);
                    break;
                case supplierResponse.success:
                    setSupplierData(supplierResponse?.data?.data);
                    setDocumentsList(supplierResponse?.data?.data?.files);
                    setLoading(false);
                    break;
                case supplierResponse.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [supplierResponse]);

    const recall = () => {
        dispatch(getAllSuppliers());
    };

    const handleFormSubmit = (data) => {
        const photoFormData = new FormData();
        if (fileId !== null) {
            photoFormData.append('deleteFileId', fileId);
        } else {
            documentsList.forEach((file) => {
                photoFormData.append('documents', file);
            });
        }
        if (supplierImageURL === null && !data.photo) {
            photoFormData.append('removePhoto', true);
        } else if (supplierImage) {
            photoFormData.append('file', supplierImage);
        }
        photoFormData.append('name', data.name);
        photoFormData.append('description', data.description);
        photoFormData.append('businessNumber', data.businessNumber);
        photoFormData.append('address[country]', data.country);
        photoFormData.append('address[city]', data.city);
        photoFormData.append('address[streetAddress]', data.address);

        if (supplierId) {
            dispatch(
                updateSupplier({
                    supplierId,
                    supplierData: photoFormData,

                    recall,
                    navigate,
                    pathname: '/suppliers'
                })
            );
        }
    };

    const handleImageChange = (event, setFieldValue) => {
        const profilePic = event?.target?.files[0];
        setSupplierImage(profilePic);
        if (profilePic) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setSupplierImageURL(result);
                    setFieldValue('photo', result);
                }
            };
            fileReader.readAsDataURL(profilePic);
        }
        setFieldValue('photo', supplierImageURL);
    };

    const handleDeleteImage = (setFieldValue) => {
        setSupplierImage(null);
        setSupplierImageURL(null);
        setFieldValue('photo', null);
        document.getElementById('file-input').value = '';
    };

    const handleButtonClick = () => {
        inputRef.current.click();
    };
    const handleFileChange = (event) => {
        const fileList = event.target.files;
        setDocumentsList((prevDocumentsList) => [...prevDocumentsList, ...fileList]);
    };

    const handleDownloadFile = async (file) => {
        try {
            const response = await fetch(file.path);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.originalName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleDeleteConfirmed = () => {
        if (fileToDeleteIndex !== null) {
            const updatedDocumentsList = documentsList.filter((document) => document.id !== fileToDeleteIndex);
            setDocumentsList(updatedDocumentsList);
        }
        setFileId(fileToDeleteIndex);
        setModalVisible(false);
        setFileToDeleteIndex(null);
    };

    const handleDeleteFile = (index) => {
        setFileToDeleteIndex(index);
        setModalVisible(true);
    };

    return (
        <Grid container>
            <Grid
                lg={9}
                p={3}
                mb={3}
                item
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
            >
                <BasicBreadcrumbs
                    firstNav={t('supplier.breadcrumbs')}
                    secondtNav={t('supplier.breadcrumbs_edit_sec')}
                    path="/suppliers"
                    firstNavIcon="firstNavIcon"
                />
            </Grid>

            {loading ? (
                <TableLoadingSkeleton />
            ) : (
                <Formik
                    initialValues={{
                        name: supplierId ? supplierData?.name : '',
                        businessNumber: supplierId ? supplierData?.businessNumber : '',

                        description: supplierId ? supplierData?.description : '',
                        country: supplierId ? supplierData?.address?.country : '',
                        city: supplierId ? supplierData?.address?.city : '',
                        address: supplierId ? supplierData?.address?.streetAddress : '',
                        photo: supplierId ? supplierData?.photo : ''
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().max(255, t('validation.name_required')).required(t('validation.name_required')),
                        businessNumber: Yup.string()
                            .max(255, t('validation.business_number_required'))
                            .required(t('validation.business_number_required')),
                        address: Yup.string().max(255, t('validation.address_required')).required(t('validation.address_required')),
                        description: Yup.string()
                            .max(255, t('validation.description_required'))
                            .required(t('validation.description_required'))
                    })}
                    onSubmit={handleFormSubmit}
                    enableReinitialize
                >
                    {({ errors, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched, dirty }) => (
                        <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <Grid p={3} container item lg={9} sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}>
                                <Grid sm={6} md={3} item>
                                    <Grid lg={12} sm={12} xs={12} xl={12} md={12} item>
                                        <Typography variant="h6" sx={{ fontSize: '14px', color: theme.palette.grey[800], p: 1 }}>
                                            {t('supplier.general_information')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    sm={6}
                                    md={8}
                                    sx={{
                                        borderLeft: matchDownXs ? 'none' : '1px solid red',
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    p={2}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Button
                                            component="label"
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'transparent'
                                                },
                                                '&:active': {
                                                    backgroundColor: 'transparent !important'
                                                },
                                                color: 'transparent'
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                accept="image/*"
                                                id="file-input"
                                                onChange={(event) => handleImageChange(event, setFieldValue)}
                                            />
                                            <Button
                                                component="label"
                                                sx={{
                                                    '&:hover': { backgroundColor: 'transparent' },
                                                    '&:active': { backgroundColor: 'transparent !important' },
                                                    color: 'transparent'
                                                }}
                                            >
                                                <Grid sx={{ width: 100, height: 100, position: 'relative' }}>
                                                    <Grid
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '50%',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        {values.photo || supplierImageURL ? (
                                                            <img
                                                                src={values.photo || supplierImageURL}
                                                                alt="Profile Icon"
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={profileIcon}
                                                                alt="Profile Icon"
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: 0,
                                                            backgroundColor: 'white',
                                                            borderRadius: '50%',
                                                            padding: '5px',
                                                            border: `2px solid ${theme.palette.secondary.main}`
                                                        }}
                                                        onClick={() => document.getElementById('file-input').click()} // Only open once
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                fontSize: '16px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: theme.palette.secondary.main
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Button>
                                        </Button>
                                        <Grid item xs={12} mt={1}>
                                            <Typography variant="subtitle2" align="center">
                                                {t('supplier.image')}
                                            </Typography>
                                        </Grid>
                                        {(supplierImageURL || values.photo) && (
                                            <Card onClick={() => handleDeleteImage(setFieldValue)} sx={{ cursor: 'pointer' }}>
                                                <Grid container direction="row" alignItems="center" justifyContent="center" mt={2}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        sx={{
                                                            backgroundColor: theme.palette.error.light + 30,
                                                            width: 120,
                                                            p: 1,
                                                            borderRadius: 2
                                                        }}
                                                    >
                                                        <DeleteIcon sx={{ color: theme.palette.error.light, fontSize: 18 }} />
                                                        <Typography sx={{ color: theme.palette.error.light, fontSize: 12 }} variant="h6">
                                                            {t('info_doctor.delete_profile_image')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} ml={3} xs={12}>
                                        <CustomTextInput
                                            id="name"
                                            fullWidth
                                            type="text"
                                            value={values.name}
                                            name="name"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <MedicalServicesOutlinedIcon style={{ color: theme.palette.grey[500] }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('supplier.name')}
                                            labelName={t('supplier.name')}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>

                                    <Grid item lg={12} ml={3} xs={12}>
                                        <CustomTextInput
                                            id="businessNumber"
                                            fullWidth
                                            type="text"
                                            value={values.businessNumber}
                                            name="businessNumber"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <BusinessOutlined style={{ color: theme.palette.grey[500] }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('supplier.business_number')}
                                            labelName={t('supplier.business_number')}
                                            error={Boolean(touched.businessNumber && errors.businessNumber)}
                                            helperText={touched.businessNumber && errors.businessNumber}
                                        />
                                    </Grid>

                                    <Grid item lg={12} ml={3} xs={12}>
                                        <CustomTextInput
                                            fullWidth
                                            id="country"
                                            type="text"
                                            value={values.country}
                                            name="country"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <FlagIcon style={{ color: theme.palette.grey[500] }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('info_admin.country')}
                                            labelName={t('info_admin.country')}
                                            error={Boolean(touched.country && errors.country)}
                                            helperText={touched.country && errors.country}
                                        />
                                    </Grid>
                                    <Grid container direction="row" ml={1} spacing={2}>
                                        <Grid item lg={6} xs={12}>
                                            <CustomTextInput
                                                fullWidth
                                                id="city"
                                                type="city"
                                                value={values.city}
                                                name="city"
                                                onChange={handleChange}
                                                icon={
                                                    <InputAdornment position="start">
                                                        <ApartmentOutlinedIcon style={{ color: theme.palette.grey[500], fontSize: 20 }} />
                                                    </InputAdornment>
                                                }
                                                placeholderText={t('info_admin.city')}
                                                labelName={t('info_admin.city')}
                                                error={Boolean(touched.city && errors.city)}
                                                helperText={touched.city && errors.city}
                                            />
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <CustomTextInput
                                                id="address"
                                                type="address"
                                                value={values.address}
                                                name="address"
                                                onChange={handleChange}
                                                icon={
                                                    <InputAdornment position="start">
                                                        <FmdGoodOutlinedIcon style={{ color: theme.palette.grey[500], fontSize: 20 }} />
                                                    </InputAdornment>
                                                }
                                                placeholderText={t('info_admin.address')}
                                                labelName={t('info_admin.address')}
                                                fullWidth
                                                error={Boolean(touched.address && errors.address)}
                                                helperText={touched.address && errors.address}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={12} ml={3} xs={12}>
                                        <CustomTextInput
                                            id="description"
                                            fullWidth
                                            type="text"
                                            value={values.description}
                                            name="description"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <DescriptionOutlined style={{ color: theme.palette.grey[500] }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('supplier.description')}
                                            labelName={t('supplier.description')}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                p={3}
                                container
                                item
                                lg={9}
                                mt={3}
                                sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
                            >
                                <Grid sm={6} md={3} item>
                                    <Grid lg={12} sm={12} xs={12} xl={12} md={12} item>
                                        <Typography variant="h6" sx={{ fontSize: '14px', color: theme.palette.grey[800], p: 1 }}>
                                            {t('supplier.general_information')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    sm={6}
                                    md={8}
                                    sx={{
                                        borderLeft: matchDownXs ? 'none' : '1px solid red',
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    p={2}
                                >
                                    <Grid container alignItems="flex-end" justifyContent="flex-end">
                                        <Button
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                            sx={{
                                                fontSize: '10px',
                                                backgroundColor: theme.palette.secondary.main,
                                                boxShadow: 'none',

                                                '&:hover': {
                                                    backgroundColor: theme.palette.secondary.main,
                                                    boxShadow: 'none'
                                                }
                                            }}
                                            onClick={handleButtonClick}
                                        >
                                            {t('info_patient.upload_file')}
                                        </Button>
                                        <input
                                            type="file"
                                            ref={inputRef}
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </Grid>

                                    {documentsList?.length > 0 && (
                                        <Grid item xs={12} sm={12} mr={2} ml={2}>
                                            <List>
                                                {documentsList.map((file, index) => (
                                                    <ListItem
                                                        key={index}
                                                        sx={{
                                                            backgroundColor: theme.palette.common.white,
                                                            boxShadow: theme.customShadows.z1,
                                                            borderRadius: 2,
                                                            mt: 2
                                                        }}
                                                    >
                                                        <IconButton aria-label="file">
                                                            <ArticleOutlinedIcon
                                                                sx={{ fontSize: '24px', color: theme.palette.grey[400] }}
                                                            />
                                                        </IconButton>
                                                        <ListItemText
                                                            primary={file?.originalName || file.name}
                                                            sx={{
                                                                '.MuiListItemText-primary': {
                                                                    color: theme.palette.grey[600],
                                                                    fontSize: '12px',
                                                                    maxWidth: '200px', // Adjust the width as needed
                                                                    overflowWrap: 'break-word'
                                                                }
                                                            }}
                                                        />
                                                        <ListItemSecondaryAction sx={{ ml: 3 }}>
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="download"
                                                                onClick={() => handleDownloadFile(file)}
                                                            >
                                                                <GetAppIcon
                                                                    sx={{ fontSize: '20px', color: theme.palette.secondary.main }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteFile(file?.id)}
                                                                sx={{ ml: 1 }}
                                                            >
                                                                <DeleteOutlineOutlinedIcon
                                                                    sx={{ color: theme.palette.error.main, fontSize: '20px' }}
                                                                />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    )}
                                    {documentsList.length === 0 && (
                                        <Grid container xs={12} sm={12} item flexDirection="column" alignItems="center" mt={6} mb={6}>
                                            <Grid sx={{ width: 100, height: 100 }}>
                                                <img src={nofile} alt="" width="100%" />
                                            </Grid>
                                            <Typography variant="body2" color="textSecondary" align="center">
                                                {t('info_patient.no_files')}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container lg={9} item justifyContent="flex-end" mb={4} xs={12} mt={3}>
                                <Grid sx={{ width: !matchDownXs ? 130 : '100%' }} alignSelf="flex-end">
                                    <CustomButton
                                        label={t('supplier.edit_button')}
                                        type="submit"
                                        backgroundColor={theme.palette.secondary.main}
                                        textColor={theme.palette.common.white}
                                        sx={{ fontSize: '12px', padding: '10px', boxShadow: 'none' }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            )}
            {modalVisible && (
                <CustomDeleteModal modalVisible={modalVisible} setModalVisible={setModalVisible} handleDelete={handleDeleteConfirmed} />
            )}
        </Grid>
    );
};

export default EditSupplierPage;
