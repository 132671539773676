import { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import CustomTable from 'components/tables/Table';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CustomDeleteModal from 'components/modal/DeleteModal';
import CustomButton from 'components/extended/Button';
import { useDispatch, useSelector } from 'react-redux';
import TableSkeleton from 'skeleton/TablePlaceholder';
import NoDataCard from 'components/cards/NoDataCard';
import { useTranslation } from 'react-i18next';
import { clearAllSuppliers, deleteSupplier, getAllSuppliers } from 'store/suppliers';
import { setPageNumber } from 'store/customization';
import { resetSearchValue } from 'store/search';
import { debounce } from 'lodash';

const SuppliersDataView = () => {
    const defaultPage = 0;
    const defaultLimit = 5;
    const [page, setPage] = useState(defaultPage);
    const [limit, setLimit] = useState(defaultLimit);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [selectedSupplierId, setSelectedSupplierId] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const suppliersResponse = useSelector((state) => state.supplierService.getAll);
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { currentPage } = useSelector((state) => state.customization.pagination);
    const searchValue = useSelector((state) => state.search.searchValue);
    const currentLanguage = i18n.language;

    useEffect(
        () => () => {
            dispatch(clearAllSuppliers());
        },
        [dispatch]
    );
    useEffect(
        () => () => {
            dispatch(resetSearchValue());
        },
        [dispatch]
    );

    const debouncedApiCall = useCallback(
        debounce((search) => {
            dispatch(getAllSuppliers({ limit, page: currentPage + 1, search }));
        }, 500),
        [dispatch, limit, currentPage]
    );

    useEffect(() => {
        if (searchValue) {
            debouncedApiCall(searchValue);
        } else {
            dispatch(getAllSuppliers({ limit, page: currentPage + 1, search: searchValue }));
        }

        return () => {
            debouncedApiCall.cancel?.();
        };
    }, [searchValue, debouncedApiCall, dispatch, limit, currentPage]);

    const columnsData = [
        { id: 'name', label: t('supplier.name'), minWidth: 250 },
        { id: 'description', label: t('supplier.description'), minWidth: 250 },
        { id: 'address', label: t('supplier.address'), minWidth: 100 },
        { id: 'businessNumber', label: t('supplier.business_number'), minWidth: 100 },
        { id: 'actions', label: t('supplier.actions'), minWidth: 100 }
    ];

    const handleNavigate = (id) => {
        navigate(`/supplier/details/${id}`);
    };

    const recall = () => {
        setPage(defaultPage);
        setLimit(defaultLimit);
        dispatch(getAllSuppliers({ limit, page: currentPage + 1 }));
    };

    const deleteSpecializationById = (id) => {
        if (id) {
            dispatch(deleteSupplier(id, recall));
        }
    };

    const onClickEdit = (id) => {
        navigate(`/supplier/edit/${id}`);
    };

    const onClickViewProfile = (id) => {
        navigate(`/supplier/details/${id}`);
    };

    useEffect(() => {
        if (suppliersResponse) {
            switch (true) {
                case suppliersResponse.loading:
                    setLoading(true);
                    break;
                case suppliersResponse.success: {
                    const modifiedData = suppliersResponse.data.data.docs.map((supplier) => ({
                        id: supplier?.id,
                        name: supplier?.name,
                        description: supplier?.description,
                        address: `${supplier.address.city} ${supplier.address.streetAddress}`,
                        businessNumber: supplier.bussinessNumber
                    }));
                    setTotalCount(suppliersResponse?.data?.data?.meta?.total);
                    setData(modifiedData);
                    setLoading(false);
                    setInitialLoading(false);
                    break;
                }
                case suppliersResponse.error:
                    setLoading(false);
                    setInitialLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [suppliersResponse]);

    const onRowsChange = (event) => {
        setLimit(event?.target?.value);
    };
    const onPageChange = (event, newPage) => {
        dispatch(setPageNumber(newPage));
    };

    return (
        <Grid container>
            {initialLoading ? (
                <TableSkeleton />
            ) : (
                <>
                    <Grid
                        lg={12}
                        md={12}
                        xs={12}
                        sm={12}
                        p={1.3}
                        mt={1}
                        container
                        item
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
                    >
                        <Grid item ml={1}>
                            <Typography variant="subtitle1">
                                {totalCount} {t('supplier.total_suppliers')}
                            </Typography>
                        </Grid>

                        <Grid item mr={1}>
                            <CustomButton
                                label={t('supplier.add_new_supplier')}
                                type="submit"
                                backgroundColor={theme.palette.secondary.main}
                                textColor={theme.palette.common.white}
                                startIcon={<AddIcon />}
                                sx={{ fontSize: matchDownXs ? '8px' : '12px', padding: matchDownXs ? '5px' : '10px', boxShadow: 'none' }}
                                onClick={() => navigate('/suppliers/create')}
                            />
                        </Grid>
                    </Grid>

                    <Grid lg={12} sx={{ mt: 2, pb: 5 }} md={12} sm={12} item xs={12}>
                        {data && data?.length !== 0 ? (
                            <CustomTable
                                columns={columnsData}
                                rows={data}
                                handleNavigate={(id) => handleNavigate(id)}
                                onClickDelete={(id) => {
                                    setModalVisible(!modalVisible);
                                    setSelectedSupplierId(id);
                                }}
                                onClickEdit={(id) => onClickEdit(id)}
                                onClickViewProfile={(id) => onClickViewProfile(id)}
                                totalCount={totalCount}
                                onRowsPerPageChange={onRowsChange}
                                onPageChange={onPageChange}
                                page={currentPage}
                                limit={limit}
                            />
                        ) : (
                            data && data?.length === 0 && <NoDataCard title={t('no_data.supplier')} />
                        )}
                    </Grid>
                </>
            )}
            {modalVisible && (
                <CustomDeleteModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    handleDelete={() => {
                        if (selectedSupplierId) {
                            deleteSpecializationById(selectedSupplierId);
                            setModalVisible(false);
                        }
                    }}
                />
            )}
        </Grid>
    );
};

export default SuppliersDataView;
