import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        getAllSuppliersStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllSuppliersSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllSuppliersFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneSupplierStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneSuppliersReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneSupplierSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneSupplierFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        createSupplierStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createSupplierSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createSupplierFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        updateSupplierStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateSupplierSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateSupplierFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteSupplierStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteSupplierSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteSupplierFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    getAllSuppliersStart,
    getAllSuppliersSuccess,
    getAllSuppliersFail,
    getOneSupplierStart,
    getOneSupplierSuccess,
    getOneSupplierFail,
    createSupplierStart,
    createSupplierSuccess,
    createSupplierFail,
    updateSupplierStart,
    updateSupplierSuccess,
    updateSupplierFail,
    deleteSupplierFail,
    deleteSupplierSuccess,
    deleteSupplierStart,
    getOneSuppliersReset
} = suppliersSlice.actions;

export const getAllSuppliers = (data) => async (dispatch) => {
    try {
        dispatch(getAllSuppliersStart());
        const response = await APIClient.get('/suppliers', {
            params: { limit: data.limit, page: data.page, search: data.search }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllSuppliersSuccess(response.data));
        } else {
            dispatch(getAllSuppliersFail(t('alerts.suppliersFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllSuppliersFail);
    }
};

export const getOneSupplier = (supplierId) => async (dispatch) => {
    try {
        dispatch(getOneSupplierStart());
        const response = await APIClient.get(`/suppliers/${supplierId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneSupplierSuccess(response.data));
        } else {
            dispatch(getOneSupplierFail(t('alerts.supplierFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneSupplierFail);
    }
};

export const createSupplier = ({ supplierData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createSupplierStart());
        const response = await APIClient.post('/suppliers', supplierData);
        if (response && response.data && response.data.success) {
            dispatch(createSupplierSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.supplierCreateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createSupplierFail);
    }
};

export const updateSupplier = ({ supplierId, supplierData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateSupplierStart());
        const response = await APIClient.patch(`/suppliers/${supplierId}`, supplierData);
        if (response && response.data && response.data.success) {
            dispatch(updateSupplierSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.supplierUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateSupplierFail);
    }
};

export const deleteSupplier = (supplierId, recall) => async (dispatch) => {
    try {
        dispatch(deleteSupplierStart());
        const response = await APIClient.patch(`/suppliers/${supplierId}`, { isActive: false });
        if (response.data && response.data && response.data.success) {
            dispatch(deleteSupplierSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.specializationDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteSupplierFail(t('alerts.specializationDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.specializationDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteSupplierFail);
    }
};
export const clearAllSuppliers = () => async (dispatch) => {
    dispatch(getOneSuppliersReset());
};
export default suppliersSlice.reducer;
