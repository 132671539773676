import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const specializationsSlice = createSlice({
    name: 'clinics',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createSpecializationStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createSpecializationSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createSpecializationFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createSpecializationReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllSpecializationsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllSpecializationsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllSpecializationsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllSpecializationsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneSpecializationStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneSpecializationSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneSpecializationFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneSpecializationReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateSpecializationStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateSpecializationSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateSpecializationFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateSpecializationReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteSpecializationStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteSpecializationSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteSpecializationFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createSpecializationFail,
    createSpecializationStart,
    createSpecializationSuccess,
    createSpecializationReset,
    getAllSpecializationsFail,
    getAllSpecializationsReset,
    getAllSpecializationsSuccess,
    getAllSpecializationsStart,
    updateSpecializationStart,
    updateSpecializationReset,
    updateSpecializationFail,
    updateSpecializationSuccess,
    deleteSpecializationFail,
    deleteSpecializationSuccess,
    getOneSpecializationFail,
    getOneSpecializationReset,
    getOneSpecializationSuccess,
    getOneSpecializationStart,
    deleteSpecializationStart
} = specializationsSlice.actions;

export const createSpecialization = ({ specializationData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createSpecializationStart());
        const response = await APIClient.post('/specializations/create', specializationData);
        if (response && response.data && response.data.success) {
            dispatch(createSpecializationSuccess(response.data));
            if (recall) recall();
            navigate(pathname);
            dispatch(addNotification({ severity: 'success', message: t('alerts.specializationCreateSuccess') }));
        } else {
            dispatch(createSpecializationFail(t('alerts.specializationCreateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createSpecializationFail);
    }
};

export const getAllSpecialization = (data) => async (dispatch) => {
    try {
        dispatch(getAllSpecializationsStart());
        const response = await APIClient.get('/specializations', {
            params: { limit: data.limit, page: data.page, search: data.search, isActive: true, language: data.language }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllSpecializationsSuccess(response.data));
        } else {
            dispatch(getAllSpecializationsFail(t('alerts.specializationFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllSpecializationsFail);
    }
};

export const getOneSpecialization = (specializationId) => async (dispatch) => {
    try {
        dispatch(getOneSpecializationStart());
        const response = await APIClient.get(`/specializations/${specializationId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneSpecializationSuccess(response.data));
        } else {
            dispatch(getOneSpecializationFail(t('alerts.specializationFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneSpecializationFail);
    }
};

export const updateSpecialization = ({ specializationId, specializationData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateSpecializationStart());
        const response = await APIClient.patch(`/specializations/${specializationId}`, specializationData);
        if (response && response.data && response.data.success) {
            dispatch(updateSpecializationSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.specializationUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updateSpecializationFail(t('alerts.specializationUpdateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateSpecializationFail);
    }
};

export const deleteSpecialization = (specializationId, recall) => async (dispatch) => {
    try {
        dispatch(deleteSpecializationStart());
        const response = await APIClient.patch(`/specializations/${specializationId}`, { isActive: false });
        if (response.data && response.data && response.data.success) {
            dispatch(deleteSpecializationSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.specializationDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteSpecializationFail(t('alerts.specializationDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.specializationDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteSpecializationFail);
    }
};
export const clearAllSpecializations = () => async (dispatch) => {
    dispatch(getAllSpecializationsReset());
};

export default specializationsSlice.reducer;
