import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import CustomButton from 'components/extended/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CustomDeleteModal = ({ modalVisible, setModalVisible, handleDelete }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const handleClose = () => {
        setModalVisible(false);
    };

    // const handleDelete = () => {
    //     setModalVisible(false);
    // };

    return (
        <Grid>
            <Dialog
                open={modalVisible}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        padding: '20px',
                        alignItems: 'center'
                    }
                }}
            >
                <DialogTitle sx={{ textAlign: 'center', color: theme.palette.grey[800] }}> {t('delete_dialog.title')}</DialogTitle>
                <DialogContent>
                    <Typography sx={{ width: 300, textAlign: 'center', color: theme.palette.grey[500] }}>
                        {' '}
                        {t('delete_dialog.message')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box>
                        <CustomButton
                            textColor={theme.palette.secondary.main}
                            sx={{ fontSize: '11px' }}
                            label={t('delete_dialog.cancel_button')}
                            onClick={handleClose}
                            variant="outlined"
                            borderOutlinedColor={theme.palette.secondary.main}
                        />
                    </Box>
                    <Box>
                        <CustomButton
                            backgroundColor={theme.palette.error.main}
                            textColor={theme.palette.common.white}
                            sx={{ fontSize: '12px' }}
                            label={t('delete_dialog.delete_button')}
                            onClick={handleDelete}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

CustomDeleteModal.propTypes = {
    modalVisible: PropTypes.bool.isRequired,
    setModalVisible: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
};

export default CustomDeleteModal;
