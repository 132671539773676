import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import axios from 'axios';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const expensesSlice = createSlice({
    name: 'expenses',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        getAllExpensesStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllExpensesSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllExpensesFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllExpensesReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneExpensesStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneExpensesSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneExpensesFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        createExpenseStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createExpenseSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createExpenseFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        updateExpenseStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateExpenseSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateExpenseFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteExpenseStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteExpenseSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteExpenseFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    getAllExpensesStart,
    getAllExpensesSuccess,
    getAllExpensesFail,
    getOneExpensesStart,
    getOneExpensesSuccess,
    getOneExpensesFail,
    createExpenseStart,
    createExpenseSuccess,
    createExpenseFail,
    updateExpenseStart,
    updateExpenseSuccess,
    updateExpenseFail,
    deleteExpenseFail,
    deleteExpenseStart,
    deleteExpenseSuccess,
    getAllExpensesReset
} = expensesSlice.actions;

export const getAllExpenses = (data) => async (dispatch) => {
    try {
        dispatch(getAllExpensesStart());
        const response = await APIClient.get('/expenses', {
            params: {
                limit: data.limit,
                page: data.page,
                startDate: data.startDate,
                endDate: data.endDate,
                ...(data.supplierId !== null && { supplierId: data.supplierId }),
                search: data.search
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllExpensesSuccess(response.data));
        } else {
            dispatch(getAllExpensesFail(t('alerts.expenseFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllExpensesFail);
    }
};

export const getOneExpense = (expenseId) => async (dispatch) => {
    try {
        dispatch(getOneExpensesStart());
        const response = await APIClient.get(`/expenses/${expenseId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneExpensesSuccess(response.data));
        } else {
            dispatch(getOneExpensesFail(t('alerts.expenseFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneExpensesFail);
    }
};

export const createExpense = ({ expensesData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createExpenseStart());
        const response = await APIClient.post('/expenses', expensesData);
        if (response && response.data && response.data.success) {
            dispatch(createExpenseSuccess(response.data));
            // if (recallReports) recallReports();
            if (recall) recall();
            navigate(pathname);
            dispatch(addNotification({ severity: 'success', message: t('alerts.expenseCreateSuccess') }));
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createExpenseFail);
    }
};

export const updateExpense = ({ expenseId, expenseData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateExpenseStart());
        const response = await APIClient.patch(`/expenses/${expenseId}`, expenseData);
        if (response && response.data && response.data.success) {
            dispatch(updateExpenseSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.expenseUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateExpenseFail);
    }
};

export const deleteExpense = (expenseId, recall) => async (dispatch) => {
    try {
        dispatch(deleteExpenseStart());
        const response = await APIClient.patch(`/expenses/${expenseId}`, { isActive: false });
        if (response.data && response.data && response.data.success) {
            dispatch(deleteExpenseSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.expenseDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteExpenseFail(t('alerts.expenseDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.expenseDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteExpenseFail);
    }
};

export const clearAllExpenses = () => async (dispatch) => {
    dispatch(getAllExpensesReset());
};

export default expensesSlice.reducer;
