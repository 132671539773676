import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const servicesSlice = createSlice({
    name: 'clinics',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createServiceStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createServiceSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createServiceFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createServiceReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllServicesStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllServicesSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllServicesFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllServicesReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneServiceStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneServiceSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneServiceFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneServiceReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateServiceStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateServiceSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateServiceFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateServiceReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteServiceStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteServiceSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteServiceFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createServiceFail,
    createServiceStart,
    createServiceSuccess,
    createServiceReset,
    getAllServicesFail,
    getAllServicesReset,
    getAllServicesSuccess,
    getAllServicesStart,
    updateServiceStart,
    updateServiceReset,
    updateServiceFail,
    updateServiceSuccess,
    deleteServiceFail,
    deleteServiceSuccess,
    getOneServiceFail,
    getOneServiceReset,
    getOneServiceSuccess,
    getOneServiceStart,
    deleteServiceStart
} = servicesSlice.actions;

export const createService = ({ serviceData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createServiceStart());

        const response = await APIClient.post('/clinic-services', serviceData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response && response.data && response.data.success) {
            dispatch(createServiceSuccess(response.data));
            if (recall) recall();
            navigate(pathname);
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceCreateSuccess') }));
        } else {
            dispatch(createServiceFail(t('alerts.serviceCreateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createServiceFail);
    }
};

export const getAllServices = (data) => async (dispatch) => {
    try {
        dispatch(getAllServicesStart());
        const response = await APIClient.get('/clinic-services', {
            params: {
                limit: data.limit,
                page: data.page,
                search: data.search,
                isActive: true,
                pagination: data.pagination,
                isVisible: data.isVisible,
                language: data.language,
                createdAt: data.createdAt
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllServicesSuccess(response.data));
        } else {
            dispatch(getAllServicesFail(t('alerts.serviceFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllServicesFail);
    }
};

export const getAllServicesLandingPage = (clinicName, data) => async (dispatch) => {
    try {
        dispatch(getAllServicesStart());
        const response = await APIClient.get(`/clinic-services/clinic/${clinicName}`, {
            params: {
                limit: data.limit,
                page: data.page,
                search: data.search,
                isActive: true,
                pagination: data.pagination,
                isVisible: data.isVisible
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllServicesSuccess(response.data));
        } else {
            dispatch(getAllServicesFail(t('alerts.serviceFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllServicesFail);
    }
};

export const getOneService = (serviceId) => async (dispatch) => {
    try {
        dispatch(getOneServiceStart());
        const response = await APIClient.get(`/clinic-services/${serviceId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneServiceSuccess(response.data));
        } else {
            dispatch(getOneServiceFail(t('alerts.serviceFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneServiceFail);
    }
};

export const updateService = ({ serviceId, serviceData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateServiceStart());
        const response = await APIClient.patch(`/clinic-services/${serviceId}`, serviceData);
        if (response && response.data && response.data.success) {
            dispatch(updateServiceSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updateServiceFail(t('alerts.serviceUpdateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateServiceFail);
    }
};

export const deleteService = (serviceId, recall) => async (dispatch) => {
    try {
        dispatch(deleteServiceStart());

        const formData = new FormData();
        formData.append('isActive', false);

        const response = await APIClient.patch(`/clinic-services/${serviceId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data && response.data.success) {
            dispatch(deleteServiceSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteServiceFail(t('alerts.serviceDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.serviceDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteServiceFail);
    }
};
export const clearAllServices = () => (dispatch) => {
    dispatch(getAllServicesReset());
};

export default servicesSlice.reducer;
