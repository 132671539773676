import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth';
import customizationReducer from './customization';
import notificationReducer from './notification';
import snackbarReducer from './snackbar';
import userReducer from './users';
import languageReducer from './language';
import calendarReducer from './calendar';
import clinicReducer from './clinics';
import reportReducer from './reports';
import patientReducer from './patients';
import doctorsReducer from './doctors';
import specializationsReducer from './specializations';
import servicesReducer from './services';
import meReducer from './me';
import searchReducer from './search';
import filesReducer from './files';
import dashboardReducer from './dashboard';
import patientsServiceReducer from './patientsService';
import revenueReducer from './revenue';
import supplierReducer from './suppliers';
import expensesReducer from './expenses';

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    whitelist: [
        'auth',
        'customization',
        'snackbar',
        'language',
        'users',
        'notification'
        // 'calendar',
        // 'clinics'
        // 'reports',
        // 'patients'
        // 'me',
        // 'doctors',
        // 'specializations'
    ]
};

const reducers = combineReducers({
    auth: authReducer,
    customization: customizationReducer,
    snackbar: snackbarReducer,
    notification: notificationReducer,
    users: userReducer,
    language: languageReducer,
    calendar: calendarReducer,
    clinics: clinicReducer,
    reports: reportReducer,
    patients: patientReducer,
    me: meReducer,
    doctors: doctorsReducer,
    specializations: specializationsReducer,
    services: servicesReducer,
    search: searchReducer,
    files: filesReducer,
    dashboard: dashboardReducer,
    patientsService: patientsServiceReducer,
    revenueService: revenueReducer,
    supplierService: supplierReducer,
    expensesService: expensesReducer

    // Add other reducers here if needed
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

const persistor = persistStore(store);

export { store, persistor };
