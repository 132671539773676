import { useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BasicBreadcrumbs from 'components/cards/BreadScrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SpecializationLoadingSkeleton from 'skeleton/SpecializationProfilePlaceholder';
import { useTranslation } from 'react-i18next';
import { getOneSupplier } from 'store/suppliers';

const SupplierDetailsPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [suppliersData, setSuppliersData] = useState();
    const suppliersResponse = useSelector((state) => state.supplierService.getOne);
    const params = useParams();
    const supplierId = params.id;
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    // const currentLanguage = i18n.language;

    useEffect(() => {
        if (supplierId) {
            dispatch(getOneSupplier(supplierId));
        }
    }, [supplierId, dispatch]);

    useEffect(() => {
        if (suppliersResponse) {
            switch (true) {
                case suppliersResponse.loading:
                    setLoading(true);
                    break;
                case suppliersResponse.success:
                    setSuppliersData(suppliersResponse?.data?.data);
                    setLoading(false);
                    break;
                case suppliersResponse.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [suppliersResponse]);

    return (
        <Grid container>
            {loading ? (
                <SpecializationLoadingSkeleton />
            ) : (
                <>
                    <Grid
                        lg={9}
                        p={3}
                        mb={3}
                        item
                        container
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
                    >
                        <BasicBreadcrumbs
                            firstNav={t('supplier.breadcrumbs')}
                            secondtNav={t('supplier.breadcrumbsSecItem')}
                            path="/suppliers"
                            firstNavIcon="firstNavIcon"
                        />
                    </Grid>
                    <Grid lg={9} item flexDirection="row" container justifyContent="space-between">
                        <Grid p={4} container sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}>
                            <Grid sm={6} md={3} item>
                                <Grid lg={12} sm={12} xs={12} xl={12} md={12} item>
                                    <Typography variant="h6">{t('supplier.general_information')}</Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                sm={6}
                                md={8}
                                item
                                sx={{
                                    borderLeft: matchDownXs ? 'none' : '1px solid red',
                                    borderColor: theme.palette.grey[200]
                                }}
                            >
                                <Grid item lg={12} ml={matchDownXs ? 0 : 3} xs={10} mt={matchDownXs ? 2 : 0} mb={1}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('supplier.name')}
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: theme.palette.grey[800], ml: 1 }}>
                                            {suppliersData?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} ml={matchDownXs ? 0 : 3} xs={10} mb={1}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('supplier.description')}:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '12px',
                                                ml: 1
                                            }}
                                        >
                                            {suppliersData?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} ml={matchDownXs ? 0 : 3} xs={10} mb={1}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('supplier.business_number')}:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '12px',
                                                ml: 1
                                            }}
                                        >
                                            {suppliersData?.bussinessNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid lg={9} mt={3} flexDirection="row" item container justifyContent="space-between">
                        <Grid p={4} container sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}>
                            <Grid sm={6} md={3} item>
                                <Grid lg={12} sm={12} xs={12} xl={12} md={12} item>
                                    <Typography variant="h6">{t('info_admin.other_information')}</Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                sm={6}
                                md={6}
                                lg={6}
                                item
                                sx={{
                                    borderLeft: '1px solid red',
                                    borderColor: theme.palette.grey[200]
                                }}
                            >
                                <Grid item lg={12} ml={3} xs={10} mb={1}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('info_admin.country')}:
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: theme.palette.grey[800], ml: 1 }}>
                                            {suppliersData?.address?.country}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} ml={3} xs={10} mb={1}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('info_admin.city')}:
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: theme.palette.grey[800], ml: 1 }}>
                                            {suppliersData?.address?.city}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} ml={3} xs={10}>
                                    <Grid container flexDirection="row">
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {t('info_admin.address')}:
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: theme.palette.grey[800], ml: 1 }}>
                                            {suppliersData?.address?.streetAddress}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default SupplierDetailsPage;
