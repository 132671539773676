import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Button } from '@mui/material';
import CustomTable from 'components/tables/Table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TableLoadingSkeleton from 'skeleton/TablePlaceholder';
import CustomDeleteModal from 'components/modal/DeleteModal';
import NoDataCard from 'components/cards/NoDataCard';
import { useTranslation } from 'react-i18next';
import DateRangeSelector from 'components/inputs/DateRangeSelector';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { makeStyles } from '@mui/styles';
import { getAllRevenue } from 'store/revenue';
import { getAllExpenses } from 'store/expenses';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles({
    root: {
        width: '100%',
        '& .MuiInputBase-root': {
            borderRadius: '4px',
            backgroundColor: 'white',
            borderWidth: '2px',
            width: '100% !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '3px',
            backgroundColor: 'white',
            borderWidth: '2px',
            '&:hover': {
                '& fieldset': {
                    borderColor: '#93babb'
                }
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderColor: '#93babb'
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            backgroundColor: 'white',
            borderRadius: '0px',
            padding: '12px',
            fontWeight: 400,
            fontSize: '0.80rem',
            color: '#9e9e9e'
        },
        '& .MuiOutlinedInput-root fieldset': {
            borderWidth: '0.5px',
            borderColor: '#eeeeee',
            borderRadius: '4px',
            boxShadow: '2px 2px 2px -1px rgba(209,209,209,0.75)'
        },
        '& input::placeholder': {
            fontWeight: 300,
            fontSize: '0.75rem'
        },
        '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px white inset',
            WebkitTextFillColor: '#9e9e9e',
            fontWeight: 400,
            fontSize: '0.80rem'
        },
        '& .MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected': {
            backgroundColor: 'red'
        }
    }
});

const BalanceDataView = () => {
    const defaultPage = 0;
    const defaultLimit = 5;
    const { t, i18n } = useTranslation();
    const [pageofRevenues, setPageOfRevenues] = useState(defaultPage);
    const [limitofRevenues, setLimitOfRevenues] = useState(defaultLimit);
    const [pageofExpenses, setPageOfExpenses] = useState(defaultPage);
    const [limitofExpenses, setLimitOfExpenses] = useState(defaultLimit);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRevenueId, setSelectedRevenueId] = useState(null);
    const [revenueData, setRevenueData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [selectedRange, setSelectedRange] = useState({ id: 'month', name: t('finance.thirdSubmenu.filter.one_month') });
    const [totalCount, setTotalCount] = useState(0);
    const [totalCountOfRevenue, setTotalCountOfRevenue] = useState(0);
    const [expenseData, setExpensesData] = useState(undefined);
    const [totalCountOfExpenses, setTotalCountOfExpenses] = useState(0);
    const [totalAmountOfExpenses, setTotalAmountOfExpenses] = useState(0);
    const [totalAmountOfRevenues, setTotalAmountOfRevenues] = useState(0);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [loadingButton, setLoadingButton] = useState(false);
    const revenueResponse = useSelector((state) => state.revenueService.getAll);
    const expensesResponse = useSelector((state) => state.expensesService.getAll);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const currentLanguage = i18n.language;

    const calculateDateRange = (selectedRange) => {
        const today = dayjs();
        let startDate = today;
        let endDate = today;

        switch (selectedRange.id) {
            case 'today':
                startDate = today;
                endDate = today;
                break;

            case 'week':
                startDate = today.subtract(7, 'day');
                endDate = today;
                break;

            case 'month':
                startDate = today.subtract(30, 'day');
                endDate = today;
                break;

            case 'year':
                startDate = today.subtract(1, 'year');
                endDate = today;
                break;

            default:
                console.log('Invalid selected range');
        }

        return {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };
    };

    useEffect(() => {
        if (selectedRange) {
            const { startDate, endDate } = calculateDateRange(selectedRange);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
        } else {
            setSelectedStartDate(selectedStartDate);
            setSelectedEndDate(selectedEndDate);
        }
    }, [selectedRange]);

    useEffect(() => {
        dispatch(
            getAllRevenue({
                limit: limitofRevenues,
                page: pageofRevenues + 1,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                language: currentLanguage
            })
        );
    }, [dispatch, limitofRevenues, pageofRevenues, selectedStartDate, selectedEndDate, currentLanguage]);

    useEffect(() => {
        dispatch(
            getAllExpenses({
                limit: limitofExpenses,
                page: pageofExpenses + 1,
                startDate: selectedStartDate,
                endDate: selectedEndDate
            })
        );
    }, [dispatch, limitofExpenses, pageofExpenses, selectedEndDate, selectedStartDate]);

    useEffect(() => {
        if (revenueResponse) {
            switch (true) {
                case revenueResponse.loading:
                    setLoading(true);
                    break;
                case revenueResponse.success: {
                    const modifiedData = revenueResponse?.data?.data.docs.map((revenue) => ({
                        id: revenue?.id,
                        service:
                            currentLanguage === 'en'
                                ? revenue?.patientService
                                    ? `${revenue?.patientService?.serviceType?.nameEn}`
                                    : `${revenue?.serviceType?.nameEn}`
                                : revenue?.patientService
                                ? `${revenue?.patientService?.serviceType?.nameSq}`
                                : `${revenue?.serviceType?.nameSq}`,
                        doctor: `${revenue.doctor?.user?.firstName} ${revenue?.doctor?.user?.lastName}`,
                        price: revenue?.amount,
                        date: dayjs(revenue?.updatedAt).format('DD/MM/YYYY')
                    }));
                    setTotalAmountOfRevenues(revenueResponse?.data?.data?.total);
                    setTotalCountOfRevenue(revenueResponse?.data?.data?.meta?.total);
                    setRevenueData(modifiedData);
                    setLoading(false);
                    setInitialLoading(false); // Set to false after first successful load
                    break;
                }
                case revenueResponse.error:
                    setLoading(false);
                    setInitialLoading(false); // Ensure it's set to false on error
                    break;
                default:
                    break;
            }
        }
    }, [revenueResponse]);

    useEffect(() => {
        if (expensesResponse) {
            switch (true) {
                case expensesResponse.loading:
                    setLoading(true);
                    break;
                case expensesResponse.success: {
                    const modifiedData = expensesResponse?.data?.data.docs.map((expense) => ({
                        id: expense?.id,
                        name: `${expense.itemName}`,
                        supplier: expense?.supplier?.name,
                        price: expense?.itemPrice,
                        description: expense?.itemDescription
                    }));
                    setTotalAmountOfExpenses(expensesResponse?.data?.data?.total);
                    setTotalCountOfExpenses(expensesResponse?.data?.data?.meta?.total);
                    setExpensesData(modifiedData);
                    setLoading(false);
                    setInitialLoading(false);
                    break;
                }
                case expensesResponse.error:
                    setLoading(false);
                    setInitialLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [expensesResponse]);

    const columnsData = [
        { id: 'service', label: t('finance.firstSubmenu.service'), minWidth: 150 },
        { id: 'doctor', label: t('finance.firstSubmenu.doctor'), minWidth: 150 },
        { id: 'price', label: t('finance.firstSubmenu.price'), minWidth: 120 }
    ];

    const onRowsExpensesChange = (event) => {
        setLimitOfExpenses(event?.target?.value);
    };
    const onPageExpensesChange = (event, newPage) => {
        setPageOfExpenses(newPage);
    };

    const onRowsRevenuesChange = (event) => {
        setLimitOfRevenues(event?.target?.value);
    };
    const onPageRevenuesChange = (event, newPage) => {
        setPageOfRevenues(newPage);
    };

    const columnsData1 = [
        { id: 'name', label: t('finance.secondSubmenu.name'), minWidth: 150 },
        { id: 'supplier', label: t('finance.secondSubmenu.supplier'), minWidth: 150 },
        { id: 'price', label: t('finance.secondSubmenu.price'), minWidth: 120 }
    ];

    const onDocumentDownload = async (reportId, patientData) => {
        APIClient.get('/finances/export', {
            params: {
                startDate: selectedStartDate,
                endDate: selectedEndDate
            },
            responseType: 'arraybuffer'
        })
            .then((res) => {
                setLoadingButton(false);
                if (res.data) {
                    const blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // MIME type for Excel files
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'report.xlsx';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
                }
            })
            .catch((err) => {
                setLoadingButton(false);
                if (err.response && err.response.data.message) {
                    dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
                } else {
                    dispatch(addNotification({ severity: 'error', message: 'Unexpected response from server' }));
                }
            });
    };

    const handleNavigate = (id) => {
        // navigate(`/finance/expenses/details/${id}`);
    };

    return (
        <Grid container flexDirection="row">
            {initialLoading ? (
                <TableLoadingSkeleton />
            ) : (
                <>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                        <Grid
                            p={1}
                            container
                            // spacing={3}
                            alignItems="center"
                            sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
                        >
                            <Grid item lg={3.9} md={3.9} sm={3.9} xs={12}>
                                <Typography variant="subtitle1">
                                    {t('finance.firstSubmenu.total_revenue')} {totalAmountOfRevenues}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t('finance.secondSubmenu.total_expenses')} {totalAmountOfExpenses}
                                </Typography>
                            </Grid>

                            <Grid item lg={8} md={8} xs={12} sm={8}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3.3} md={3.3} xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    paddingBottom: '6px',
                                                    color: theme.palette.grey[500]
                                                }}
                                            >
                                                {t('finance.thirdSubmenu.filter.start_time')}
                                            </Typography>
                                            <DatePicker
                                                // minDate={dayjs()}
                                                value={dayjs(selectedStartDate)}
                                                format="DD/MM/YYYY"
                                                onChange={(newValue) => {
                                                    setSelectedStartDate(newValue.$d);
                                                }}
                                                slotProps={{
                                                    day: {
                                                        sx: {
                                                            '&.MuiPickersDay-root.Mui-selected': {
                                                                backgroundColor: theme.palette.secondary.main
                                                            },
                                                            '&.MuiPickersDay-root': {
                                                                '&:hover': {
                                                                    backgroundColor: theme.palette.secondary.light + 50
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                                className={classes.root}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item lg={3.3} md={3.3} xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    paddingBottom: '6px',
                                                    color: theme.palette.grey[500]
                                                }}
                                            >
                                                {t('finance.thirdSubmenu.filter.end_time')}
                                            </Typography>
                                            <DatePicker
                                                // minDate={dayjs()}
                                                value={dayjs(selectedEndDate)}
                                                format="DD/MM/YYYY"
                                                onChange={(newValue) => {
                                                    setSelectedEndDate(newValue.$d);
                                                }}
                                                slotProps={{
                                                    day: {
                                                        sx: {
                                                            '&.MuiPickersDay-root.Mui-selected': {
                                                                backgroundColor: theme.palette.secondary.main
                                                            },
                                                            '&.MuiPickersDay-root': {
                                                                '&:hover': {
                                                                    backgroundColor: theme.palette.secondary.light + 50
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                                className={classes.root}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item lg={3.3} md={3.3} xs={12} sm={4}>
                                        <DateRangeSelector
                                            selectedRange={selectedRange}
                                            setSelectedRange={setSelectedRange}
                                            options={[
                                                { id: 'today', name: t('finance.thirdSubmenu.filter.today') },
                                                { id: 'week', name: t('finance.thirdSubmenu.filter.one_week') },
                                                { id: 'month', name: t('finance.thirdSubmenu.filter.one_month') },
                                                { id: 'year', name: t('finance.thirdSubmenu.filter.one_year') }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={2} xs={12} sm={4} mt={4}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.secondary.main }}
                                            onClick={onDocumentDownload}
                                            startIcon={<DownloadIcon />}
                                        >
                                            Download
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid lg={6} sx={{ mt: 2 }} md={6} sm={12} item xs={12}>
                            {revenueData && revenueData?.length !== 0 ? (
                                <CustomTable
                                    handleNavigate={(userId) => handleNavigate(userId)}
                                    columns={columnsData}
                                    rows={revenueData}
                                    totalCount={totalCountOfRevenue}
                                    onRowsPerPageChange={onRowsRevenuesChange}
                                    onPageChange={onPageRevenuesChange}
                                    page={pageofRevenues}
                                    limit={limitofRevenues}
                                />
                            ) : (
                                revenueData &&
                                revenueData?.length === 0 &&
                                expenseData?.length !== 0 && <NoDataCard balance={t('no_data.revenues')} />
                            )}
                        </Grid>

                        <Grid lg={6} sx={{ mt: 2 }} md={6} sm={12} item xs={12}>
                            {expenseData && expenseData?.length !== 0 ? (
                                <CustomTable
                                    handleNavigate={(userId) => handleNavigate(userId)}
                                    columns={columnsData1}
                                    rows={expenseData}
                                    totalCount={totalCountOfExpenses}
                                    onRowsPerPageChange={onRowsExpensesChange}
                                    onPageChange={onPageExpensesChange}
                                    page={pageofExpenses}
                                    limit={limitofExpenses}
                                />
                            ) : (
                                expenseData &&
                                expenseData?.length === 0 &&
                                revenueData?.length !== 0 && <NoDataCard balance={t('no_data.expenses')} />
                            )}
                        </Grid>
                    </Grid>

                    {expenseData?.length === 0 && revenueData?.length === 0 && (
                        <Grid container>
                            <NoDataCard balance={t('no_data.balance')} balanceText />
                        </Grid>
                    )}
                    {expenseData?.length !== 0 && revenueData?.length !== 0 && (
                        <Grid
                            p={3}
                            container
                            // spacing={3}
                            mt={2}
                            alignItems="center"
                            sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
                            // mb={2}
                            justifyContent="flex-end"
                            // mt={20}
                        >
                            Totali i balancit: {Number(totalAmountOfRevenues) - Number(totalAmountOfExpenses)}
                        </Grid>
                    )}
                </>
            )}
            {modalVisible && (
                <CustomDeleteModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    handleDelete={() => {
                        if (selectedRevenueId) {
                            // deleteDoctorById(selectedDoctorId);
                            setModalVisible(false);
                        }
                    }}
                />
            )}
        </Grid>
    );
};

export default BalanceDataView;
