import { Grid, Typography, useMediaQuery, InputAdornment, Button, Box, Card } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import BasicBreadcrumbs from 'components/cards/BreadScrumbs';
import CustomTextInput from 'components/inputs/TextInput';
import CustomButton from 'components/extended/Button';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import { useTranslation } from 'react-i18next';
import { BusinessOutlined, DescriptionOutlined } from '@mui/icons-material';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import { createSupplier, getAllSuppliers } from 'store/suppliers';
import { useState } from 'react';
import profileIcon from '../../../assets/images/empty-image.png';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const AddSupplierPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [supplierImage, setSupplierImage] = useState();
    const [supplierImageURL, setSupplierImageURL] = useState(null);

    const recall = () => {
        dispatch(getAllSuppliers());
    };

    const handleFormSubmit = (data) => {
        const photoFormData = new FormData();
        photoFormData.append('file', supplierImage);
        photoFormData.append('name', data.name);
        photoFormData.append('description', data.description);
        photoFormData.append('businessNumber', data.businessNumber);
        photoFormData.append('address[country]', data.country);
        photoFormData.append('address[city]', data.city);
        photoFormData.append('address[streetAddress]', data.address);

        dispatch(
            createSupplier({
                supplierData: photoFormData,
                recall,
                navigate,
                pathname: '/suppliers'
            })
        );
    };

    const handleImageChange = (event, setFieldValue) => {
        const profilePic = event?.target?.files[0];
        setSupplierImage(profilePic);
        if (profilePic) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setSupplierImageURL(result);
                    setFieldValue('photo', result);
                }
            };
            fileReader.readAsDataURL(profilePic);
        }
        setFieldValue('photo', supplierImageURL);
    };

    const handleDeleteImage = (setFieldValue) => {
        setSupplierImage(null);
        setSupplierImageURL(null);
        setFieldValue('photo', null);
        document.getElementById('file-input').value = '';
    };

    return (
        <Grid container>
            <Grid
                lg={9}
                p={3}
                mb={3}
                item
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}
            >
                <BasicBreadcrumbs
                    firstNav={t('supplier.breadcrumbs')}
                    secondtNav={t('supplier.breadcrumbsSecItem')}
                    path="/suppliers"
                    firstNavIcon="firstNavIcon"
                />
            </Grid>

            <Formik
                initialValues={{
                    name: '',
                    businessNumber: '',
                    country: '',
                    city: '',
                    address: '',
                    description: '',
                    photo: ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255, t('validation.name_required')).required(t('validation.name_required')),
                    businessNumber: Yup.string()
                        .max(255, t('validation.business_number_required'))
                        .required(t('validation.business_number_required')),

                    description: Yup.string().max(255, t('validation.description_required')).required(t('validation.description_required')),
                    country: Yup.string().max(255).required(t('validation.country_required')),
                    city: Yup.string().max(255).required(t('validation.city_required')),
                    address: Yup.string().required(t('validation.address_required'))
                })}
                onSubmit={handleFormSubmit}
            >
                {({ errors, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid p={3} container item lg={9} sx={{ backgroundColor: theme.palette.common.white, borderRadius: '10px' }}>
                            <Grid sm={6} md={3} item>
                                <Grid lg={12} sm={12} xs={12} xl={12} md={12} item>
                                    <Typography variant="h6" sx={{ fontSize: '14px', color: theme.palette.grey[800], p: 1 }}>
                                        {t('supplier.general_information')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                sm={6}
                                md={8}
                                sx={{
                                    borderLeft: matchDownXs ? 'none' : '1px solid red',
                                    borderColor: theme.palette.grey[200]
                                }}
                                p={2}
                            >
                                <Grid container alignItems="center" justifyContent="center">
                                    <Button
                                        component="label"
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent'
                                            },
                                            '&:active': {
                                                backgroundColor: 'transparent !important'
                                            },
                                            color: 'transparent'
                                        }}
                                    >
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept="image/*"
                                            id="file-input"
                                            onChange={(event) => handleImageChange(event, setFieldValue)}
                                        />
                                        <Button
                                            component="label"
                                            sx={{
                                                '&:hover': { backgroundColor: 'transparent' },
                                                '&:active': { backgroundColor: 'transparent !important' },
                                                color: 'transparent'
                                            }}
                                        >
                                            <Grid sx={{ width: 100, height: 100, position: 'relative' }}>
                                                <Grid
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {values.photo || supplierImageURL ? (
                                                        <img
                                                            src={values.photo || supplierImageURL}
                                                            alt="Profile Icon"
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={profileIcon}
                                                            alt="Profile Icon"
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        />
                                                    )}
                                                </Grid>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 0,
                                                        backgroundColor: 'white',
                                                        borderRadius: '50%',
                                                        padding: '5px',
                                                        border: `2px solid ${theme.palette.secondary.main}`
                                                    }}
                                                    onClick={() => document.getElementById('file-input').click()} // Only open once
                                                >
                                                    <EditIcon
                                                        sx={{
                                                            fontSize: '16px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            color: theme.palette.secondary.main
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Button>
                                    </Button>
                                    <Grid item xs={12} mt={1}>
                                        <Typography variant="subtitle2" align="center">
                                            {t('supplier.image')}
                                        </Typography>
                                    </Grid>
                                    {supplierImageURL && (
                                        <Card onClick={() => handleDeleteImage(setFieldValue)} sx={{ cursor: 'pointer' }}>
                                            <Grid container direction="row" alignItems="center" justifyContent="center" mt={2}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    sx={{
                                                        backgroundColor: theme.palette.error.light + 30,
                                                        width: 120,
                                                        p: 1,
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    <DeleteIcon sx={{ color: theme.palette.error.light, fontSize: 18 }} />
                                                    <Typography sx={{ color: theme.palette.error.light, fontSize: 12 }} variant="h6">
                                                        {t('info_doctor.delete_profile_image')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )}
                                </Grid>

                                <Grid item lg={12} ml={3} xs={12}>
                                    <CustomTextInput
                                        id="name"
                                        fullWidth
                                        type="text"
                                        value={values.name}
                                        name="name"
                                        onChange={handleChange}
                                        icon={
                                            <InputAdornment position="start">
                                                <MedicalServicesOutlinedIcon style={{ color: theme.palette.grey[500] }} />
                                            </InputAdornment>
                                        }
                                        placeholderText={t('supplier.name')}
                                        labelName={t('supplier.name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>

                                <Grid item lg={12} ml={3} xs={12}>
                                    <CustomTextInput
                                        id="businessNumber"
                                        fullWidth
                                        type="text"
                                        value={values.businessNumber}
                                        name="businessNumber"
                                        onChange={handleChange}
                                        icon={
                                            <InputAdornment position="start">
                                                <BusinessOutlined style={{ color: theme.palette.grey[500] }} />
                                            </InputAdornment>
                                        }
                                        placeholderText={t('supplier.business_number')}
                                        labelName={t('supplier.business_number')}
                                        error={Boolean(touched.businessNumber && errors.businessNumber)}
                                        helperText={touched.businessNumber && errors.businessNumber}
                                    />
                                </Grid>

                                <Grid item lg={12} ml={3} xs={12}>
                                    <CustomTextInput
                                        fullWidth
                                        id="country"
                                        type="text"
                                        value={values.country}
                                        name="country"
                                        onChange={handleChange}
                                        icon={
                                            <InputAdornment position="start">
                                                <FlagIcon style={{ color: theme.palette.grey[500] }} />
                                            </InputAdornment>
                                        }
                                        placeholderText={t('info_admin.country')}
                                        labelName={t('info_admin.country')}
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={touched.country && errors.country}
                                    />
                                </Grid>
                                <Grid container direction="row" ml={1} spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        <CustomTextInput
                                            fullWidth
                                            id="city"
                                            type="city"
                                            value={values.city}
                                            name="city"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <ApartmentOutlinedIcon style={{ color: theme.palette.grey[500], fontSize: 20 }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('info_admin.city')}
                                            labelName={t('info_admin.city')}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <CustomTextInput
                                            id="address"
                                            type="address"
                                            value={values.address}
                                            name="address"
                                            onChange={handleChange}
                                            icon={
                                                <InputAdornment position="start">
                                                    <FmdGoodOutlinedIcon style={{ color: theme.palette.grey[500], fontSize: 20 }} />
                                                </InputAdornment>
                                            }
                                            placeholderText={t('info_admin.address')}
                                            labelName={t('info_admin.address')}
                                            fullWidth
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} ml={3} xs={12}>
                                    <CustomTextInput
                                        id="description"
                                        fullWidth
                                        type="text"
                                        value={values.description}
                                        name="description"
                                        onChange={handleChange}
                                        icon={
                                            <InputAdornment position="start">
                                                <DescriptionOutlined style={{ color: theme.palette.grey[500] }} />
                                            </InputAdornment>
                                        }
                                        placeholderText={t('supplier.description')}
                                        labelName={t('supplier.description')}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container lg={9} item justifyContent="flex-end" mb={4} xs={12} mt={3}>
                            <Grid sx={{ width: !matchDownXs ? 130 : '100%' }} alignSelf="flex-end">
                                <CustomButton
                                    label={t('supplier.create_button')}
                                    type="submit"
                                    backgroundColor={theme.palette.secondary.main}
                                    textColor={theme.palette.common.white}
                                    sx={{ fontSize: '12px', padding: '10px', boxShadow: 'none' }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    );
};

export default AddSupplierPage;
