import { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Menu,
    MenuItem,
    IconButton,
    ListItemText,
    ListItemIcon,
    Box,
    Typography,
    Grid,
    useMediaQuery,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MainCard from '../cards/MainCard';
import { Link } from 'react-router-dom';
import NotesIcon from '@mui/icons-material/Notes';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import profileIcon from '../../assets/images/profile-icon.png';
import PropTypes from 'prop-types';
import CustomButton from 'components/extended/Button';
import mediaIcon from '../../assets/images/mediaPlaceholder.jpg';
import { useTranslation } from 'react-i18next';

const CustomTable = ({
    columns,
    cellStyle,
    getStatusStyle,
    handleNavigate,
    onClickDelete,
    onClickEdit,
    rows,
    onClickViewProfile,
    totalCount,
    onRowsPerPageChange,
    onPageChange,
    page,
    limit,
    onOpenModal
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const { t } = useTranslation();
    const theme = useTheme();

    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeDropdownModal = (rowId) => {
        setAnchorEl(null);
        onClickDelete(rowId);
    };

    const handleEditNavigate = (userId) => {
        setAnchorEl(null);
        onClickEdit(userId);
    };
    const handleViewProfile = (userId) => {
        setAnchorEl(null);
        onClickViewProfile(userId);
    };
    const handleNavigateFromColumn = (userId) => {
        handleNavigate(userId);
    };

    return (
        <MainCard content={false}>
            <TableContainer sx={{ maxHeight: 940 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    sx={{ py: 3 }}
                                    key={column.id}
                                    align={column.align}
                                    style={{ ...cellStyle, minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow sx={{ py: 2 }} hover role="checkbox" tabIndex={-1} key={row.id}>
                                {columns?.map((column) => {
                                    if (column.id === 'actions') {
                                        return (
                                            <TableCell key={column.id} align={column.align} style={{ display: 'flex' }}>
                                                <IconButton
                                                    onClick={(event) => handleClick(event, row)}
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        '&:hover': {
                                                            backgroundColor: 'transparent'
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        border={1}
                                                        borderRadius={1}
                                                        sx={{
                                                            display: 'flex',
                                                            padding: '2px',
                                                            color: theme.palette.secondary.main,
                                                            borderColor: theme.palette.secondary.main
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </Box>
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl) && selectedRow?.id === row.id}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem
                                                        onClick={() => handleViewProfile(row.id)}
                                                        to="#"
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.primary.light,
                                                                color: theme.palette.secondary.main,
                                                                '& > .MuiListItemIcon-root': {
                                                                    color: theme.palette.secondary.main
                                                                },
                                                                '& .MuiListItemText-primary': {
                                                                    color: theme.palette.secondary.main
                                                                }
                                                            },
                                                            '& > .MuiListItemIcon-root': {
                                                                color: theme.palette.grey[500]
                                                            },
                                                            '& .MuiListItemText-primary': {
                                                                color: theme.palette.grey[500]
                                                            }
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <NotesIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('table.view')} />
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => handleEditNavigate(row.id)}
                                                        to="#"
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.primary.light,
                                                                color: theme.palette.secondary.main,
                                                                '& > .MuiListItemIcon-root': {
                                                                    color: theme.palette.secondary.main
                                                                },
                                                                '& .MuiListItemText-primary': {
                                                                    color: theme.palette.secondary.main
                                                                }
                                                            },
                                                            '& > .MuiListItemIcon-root': {
                                                                color: theme.palette.grey[500]
                                                            },
                                                            '& .MuiListItemText-primary': {
                                                                color: theme.palette.grey[500]
                                                            }
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <EditIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('table.edit')} />
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => closeDropdownModal(row.id)}
                                                        to="#"
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.primary.light,
                                                                color: theme.palette.secondary.main,
                                                                '& > .MuiListItemIcon-root': {
                                                                    color: theme.palette.secondary.main
                                                                },
                                                                '& .MuiListItemText-primary': {
                                                                    color: theme.palette.secondary.main
                                                                }
                                                            },
                                                            '& > .MuiListItemIcon-root': {
                                                                color: theme.palette.grey[500]
                                                            },
                                                            '& .MuiListItemText-primary': {
                                                                color: theme.palette.grey[500]
                                                            }
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <DeleteIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('table.delete')} />
                                                    </MenuItem>
                                                </Menu>
                                                {column.status === 'status' ? (
                                                    <>
                                                        <CustomButton
                                                            label={t('appointments.approve')}
                                                            type="submit"
                                                            backgroundColor={theme.palette.secondary.main}
                                                            textColor={theme.palette.common.white}
                                                            onClick={() => onOpenModal('approve', row.id)}
                                                            sx={{
                                                                fontSize: !matchDownXs ? '12px' : '10px',
                                                                padding: !matchDownXs ? '10px' : '6px',
                                                                boxShadow: 'none',
                                                                margin: '8px'
                                                            }}
                                                        />
                                                        <CustomButton
                                                            label={t('appointments.expire')}
                                                            type="submit"
                                                            backgroundColor={theme.palette.error.main}
                                                            textColor={theme.palette.common.white}
                                                            onClick={() => onOpenModal('expire', row.id)}
                                                            sx={{
                                                                fontSize: !matchDownXs ? '12px' : '10px',
                                                                padding: !matchDownXs ? '10px' : '6px',
                                                                boxShadow: 'none',
                                                                margin: '8px'
                                                            }}
                                                        />
                                                    </>
                                                ) : null}
                                            </TableCell>
                                        );
                                    }
                                    if (column.id === 'status') {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                <Typography
                                                    sx={{
                                                        ...getStatusStyle(row.status),
                                                        padding: '4px',
                                                        fontSize: '12px',
                                                        borderRadius: 1,
                                                        textAlign: 'center',
                                                        width: 100
                                                    }}
                                                >
                                                    {' '}
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </Typography>
                                            </TableCell>
                                        );
                                    }
                                    if (column.id === 'availability') {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                <Typography
                                                    sx={{
                                                        padding: '4px',
                                                        fontSize: '12px',
                                                        borderRadius: 1,
                                                        textAlign: 'center',
                                                        backgroundColor: value === 'Active' ? '#d1e9f0' : theme.palette.grey[300],
                                                        color: value === 'Active' ? '#2264b5' : theme.palette.grey[800],
                                                        width: 100
                                                    }}
                                                >
                                                    {' '}
                                                    {value}
                                                </Typography>
                                            </TableCell>
                                        );
                                    }

                                    if (column.id === 'image' || column.id === 'photo') {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                <Grid
                                                    sx={{
                                                        width: 45,
                                                        height: 45,
                                                        borderRadius: '50%',
                                                        overflow: 'hidden',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {value ? (
                                                        <img
                                                            src={value}
                                                            alt="avatar"
                                                            width="100%"
                                                            height="100%"
                                                            style={{ objectFit: 'cover' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={column.id === 'image' ? profileIcon : mediaIcon}
                                                            alt="avatar"
                                                            width="100%"
                                                            height="100%"
                                                            style={{ objectFit: 'cover' }}
                                                        />
                                                    )}
                                                </Grid>
                                            </TableCell>
                                        );
                                    }

                                    const value = row[column.id];
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleNavigateFromColumn(row.id)}
                                        >
                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={limit}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                labelRowsPerPage={t('table.rows_per_page')}
            />
        </MainCard>
    );
};

CustomTable.propTypes = {
    columns: PropTypes.array.isRequired,
    cellStyle: PropTypes.object,
    getStatusStyle: PropTypes.func,
    handleNavigate: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
};

export default CustomTable;
