import { createSlice } from '@reduxjs/toolkit';
import config from 'config';

export const initialState = {
    isOpen: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    navType: config.theme,
    presetColor: config.presetColor,
    locale: config.i18n,
    rtlLayout: config.rtlLayout,
    opened: true,
    pagination: {
        currentPage: 0
    },
    selectedAppointments: 'futureAppointments',
    startDate: new Date(),
    endDate: new Date(),
    supplier: null,
    doctor: null
};

const customization = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        menuOpen(state, action) {
            const id = action.payload;
            state.isOpen = [id];
        },
        menuType(state, action) {
            state.navType = action.payload;
        },
        presetColors(state, action) {
            state.presetColor = action.payload;
        },
        themeLocale(state, action) {
            state.locale = action.payload;
        },
        themeRtl(state, action) {
            state.rtlLayout = action.payload;
        },
        setMenu(state, action) {
            state.opened = action.payload;
        },
        setFontFamily(state, action) {
            state.fontFamily = action.payload;
        },
        setBorderRadius(state, action) {
            state.borderRadius = action.payload;
        },
        setOutlinedFilled(state, action) {
            state.outlinedFilled = action.payload;
        },
        setPageNumber(state, action) {
            state.pagination.currentPage = action.payload;
        },
        setSelectedAppointments(state, action) {
            state.selectedAppointments = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setSupplier(state, action) {
            state.supplier = action.payload;
        },
        setDoctor(state, action) {
            state.doctor = action.payload;
        }
    }
});

export const {
    menuOpen,
    menuType,
    presetColors,
    themeLocale,
    themeRtl,
    setMenu,
    setFontFamily,
    setBorderRadius,
    setOutlinedFilled,
    setPageNumber,
    setSelectedAppointments,
    setStartDate,
    setEndDate,
    setSupplier,
    setDoctor
} = customization.actions;

export default customization.reducer;
