import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';

const TableLoadingSkeleton = () => {
    const numberOfRows = 7;
    const numberOfColumns = 4;

    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 2,
                    mb: 3,
                    mt: 3
                }}
            >
                <Box mb={3}>
                    <Skeleton variant="text" width="15%" animation="wave" />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {Array.from(Array(numberOfColumns).keys()).map((index) => (
                                <TableCell key={index}>
                                    <Skeleton variant="text" animation="wave" />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from(Array(numberOfRows).keys()).map((rowIndex) => (
                            <TableRow key={rowIndex}>
                                {Array.from(Array(numberOfColumns).keys()).map((colIndex) => (
                                    <TableCell key={colIndex}>
                                        <Skeleton variant="text" animation="wave" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TableLoadingSkeleton;
