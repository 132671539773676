import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Typography, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CustomTextInput = ({
    icon,
    placeholderText,
    labelName,
    value,
    name,
    id,
    type,
    onChange,
    endAdornment,
    defaultValue,
    error,
    helperText,
    filter,
    ...other
}) => {
    const [focused, setFocused] = useState(false);
    const theme = useTheme();

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <>
            <Typography
                variant="h6"
                style={{
                    paddingBottom: '6px',
                    color: theme.palette.grey[500]
                }}
            >
                {labelName}
            </Typography>
            <TextField
                {...other}
                id={id}
                value={value}
                name={name}
                type={type}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                variant="outlined"
                defaultValue={defaultValue}
                error={Boolean(error)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '4px',
                        backgroundColor: theme.palette.background.default,
                        borderWidth: '2px',
                        '&:hover': {
                            '& fieldset': {
                                borderColor: theme.palette.secondary.main // Change border color on hover
                            }
                        },
                        '&.Mui-focused': {
                            '& fieldset': {
                                borderColor: theme.palette.secondary.main // Change border color when focused
                            }
                        }
                    },
                    '& .MuiOutlinedInput-input': {
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '0px',
                        // padding: '14px',
                        fontFamily: theme.typography.h6,
                        color: theme.palette.grey[500],
                        padding: filter ? '12px !important' : '14px'
                    },
                    '& .MuiOutlinedInput-root fieldset': {
                        borderWidth: '0.5px', // Adjusts the border width for the fieldset
                        borderColor: theme.palette.grey[200],
                        borderRadius: '4px',
                        boxShadow: '2px 2px 2px -1px rgba(209,209,209,0.75)'
                    },
                    '& input::placeholder': {
                        fontFamily: theme.typography.placeholderText
                    },
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px white inset',
                        WebkitTextFillColor: theme.palette.grey[500],
                        fontFamily: theme.typography.h6 // Change the background color when input is filled
                    }
                }}
                InputProps={{
                    startAdornment: !focused ? icon : null,
                    endAdornment
                }}
                placeholder={placeholderText}
            />
            {!filter && (
                <FormHelperText
                    error={Boolean(error)}
                    // style={{ minHeight: '5px' }} // Set a minHeight to reserve space
                >
                    {helperText || error || ' '}
                </FormHelperText>
            )}
        </>
    );
};

CustomTextInput.propTypes = {
    icon: PropTypes.node,
    placeholderText: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    endAdornment: PropTypes.node,
    defaultValue: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    helperText: PropTypes.string
};

export default CustomTextInput;
