import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BasicBreadcrumbs = ({ firstNav, secondtNav, separator, path, onClickTab, firstNavIcon }) => {
    const theme = useTheme();

    return (
        <Breadcrumbs separator={secondtNav ? separator : null} aria-label="breadcrumb">
            <Link
                onClick={onClickTab}
                to={path}
                underline="none"
                component="button"
                style={{
                    textDecoration: 'none',
                    color: theme.palette.grey[800],
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '15px',
                    '&:hover': { color: 'red' }
                }}
            >
                {firstNavIcon ? <ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} /> : null}
                {firstNav}
            </Link>
            <Typography sx={{ color: theme.palette.grey[800], fontSize: '15px' }}>{secondtNav}</Typography>
        </Breadcrumbs>
    );
};

BasicBreadcrumbs.propTypes = {
    firstNav: PropTypes.string.isRequired,
    secondtNav: PropTypes.string,
    separator: PropTypes.node,
    path: PropTypes.string.isRequired,
    onClickTab: PropTypes.func
};
export default BasicBreadcrumbs;
