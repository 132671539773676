import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomButton = ({
    label,
    disabled,
    type,
    backgroundColor,
    textColor,
    onChange,
    onClick,
    variant,
    borderOutlinedColor,
    sx,
    ...other
}) => {
    const theme = useTheme();

    return (
        <>
            <Button
                {...other}
                disableElevation
                disabled={disabled}
                fullWidth
                size="large"
                type={type}
                variant={variant}
                sx={{
                    disabledButton: {
                        color: theme.palette.grey[500],
                        backgroundColor: theme.palette.grey[200],
                        '&:hover': {
                            backgroundColor: theme.palette.grey[200]
                        }
                    },
                    boxShadow: theme.customShadows.z8,
                    color: disabled ? theme.palette.grey[500] : textColor,
                    backgroundColor: disabled ? theme.palette.grey[300] : backgroundColor,
                    borderColor: disabled ? 'transparent' : borderOutlinedColor,
                    '&:hover': {
                        ...(backgroundColor && { backgroundColor }),
                        opacity: 0.9,
                        borderColor: borderOutlinedColor
                    },

                    padding: '10px 24px',
                    ...sx
                }}
                onChange={onChange}
                onClick={onClick}
            >
                {label}
            </Button>
        </>
    );
};

CustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    borderOutlinedColor: PropTypes.string,
    sx: PropTypes.object
};

export default CustomButton;
