import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SuppliersDataView from 'views/suppliers/SuppliersData';
import AddSupplierPage from 'views/suppliers/AddSupplier';
import EditSupplierPage from 'views/suppliers/EditSupplier';
import SupplierDetailsPage from 'views/suppliers/SupplierDetails';
import BalanceDataView from 'views/finance/balance/BalanceData';

const ExpensesPage = Loadable(lazy(() => import('views/finance/expenses')));
const ExpenseDetailsScreen = Loadable(lazy(() => import('views/finance/expenses/ExpenseDetails')));
const RevenueDetailsScreen = Loadable(lazy(() => import('views/finance/revenue/RevenuDetails')));
const RevenuePage = Loadable(lazy(() => import('views/finance/revenue')));
const AddExpensesPage = Loadable(lazy(() => import('views/finance/expenses/AddExpenses')));
const EditExpensePage = Loadable(lazy(() => import('views/finance/expenses/EditExpense')));
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const PatientsPage = Loadable(lazy(() => import('views/patients')));
const PatientsProfilePage = Loadable(lazy(() => import('views/patients/PatientProfile')));
const DentalPatientsPage = Loadable(lazy(() => import('views/patients/DentalPatientsProfile')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const AppointmentPage = Loadable(lazy(() => import('views/appointments')));
const AddPatientPage = Loadable(lazy(() => import('views/patients/AddPatient')));
const DoctorsPage = Loadable(lazy(() => import('views/doctors')));
const DoctorProfilePage = Loadable(lazy(() => import('views/doctors/DoctorProfile')));
const EditPatientPage = Loadable(lazy(() => import('views/patients/EditPatient')));
const AppointmentsDetailsPage = Loadable(lazy(() => import('views/appointments/AppointmentsDetails')));
const AddAppointmentPage = Loadable(lazy(() => import('views/appointments/AddAppointment')));
const AddDoctorPage = Loadable(lazy(() => import('views/doctors/AddDoctor')));
const EditDoctorPage = Loadable(lazy(() => import('views/doctors/EditDoctor')));
const EditAppointmentPage = Loadable(lazy(() => import('views/appointments/EditAppointment')));
const EditSpecializationPage = Loadable(lazy(() => import('views/specializations/EditSpecialization')));
const AddSpecializationPage = Loadable(lazy(() => import('views/specializations/AddSpecialization')));
const SpecializationProfilePage = Loadable(lazy(() => import('views/specializations/SpecializationProfile')));
const SpecializationPage = Loadable(lazy(() => import('views/specializations')));
const ServicesPage = Loadable(lazy(() => import('views/services')));
const ServiceProfilePage = Loadable(lazy(() => import('views/services/ServicesProfile')));
const AddServicePage = Loadable(lazy(() => import('views/services/AddServices')));
const EditServicePage = Loadable(lazy(() => import('views/services/EditServices')));
const MyProfile = Loadable(lazy(() => import('views/me/Profile')));

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/patients',
            element: <PatientsPage />
        },
        {
            path: '/patient/profile/:id',
            element: <PatientsProfilePage />
        },
        {
            path: '/patient/profile-info/:id',
            element: <DentalPatientsPage />
        },
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/appointments',
            element: <AppointmentPage />
        },
        {
            path: '/appointment/profile/:id',
            element: <AppointmentsDetailsPage />
        },
        {
            path: '/appointment/create',
            element: <AddAppointmentPage />
        },
        {
            path: '/appointment/edit/:id',
            element: <EditAppointmentPage />
        },
        {
            path: '/patient/create',
            element: <AddPatientPage />
        },
        {
            path: '/patient/edit/:id',
            element: <EditPatientPage />
        },
        {
            path: '/doctors',
            element: <DoctorsPage />
        },
        {
            path: '/doctor/profile/:id',
            element: <DoctorProfilePage />
        },
        {
            path: '/doctor/create',
            element: <AddDoctorPage />
        },
        {
            path: '/doctor/edit/:id',
            element: <EditDoctorPage />
        },
        {
            path: '/services',
            element: <ServicesPage />
        },
        {
            path: '/service/profile/:id',
            element: <ServiceProfilePage />
        },
        {
            path: '/service/create',
            element: <AddServicePage />
        },
        {
            path: '/service/edit/:id',
            element: <EditServicePage />
        },
        {
            path: '/specializations',
            element: <SpecializationPage />
        },
        {
            path: '/specialization/profile/:id',
            element: <SpecializationProfilePage />
        },
        {
            path: '/specialization/create',
            element: <AddSpecializationPage />
        },
        {
            path: '/specialization/edit/:id',
            element: <EditSpecializationPage />
        },
        {
            path: '/profile/:id',
            element: <MyProfile />
        },
        {
            path: '/finance/expenses',
            element: <ExpensesPage />
        },
        {
            path: '/finance/expenses/create',
            element: <AddExpensesPage />
        },
        {
            path: '/finance/expenses/edit/:id',
            element: <EditExpensePage />
        },
        {
            path: '/finance/expenses/details/:id',
            element: <ExpenseDetailsScreen />
        },
        {
            path: '/finance/revenue',
            element: <RevenuePage />
        },
        {
            path: '/finance/revenue/details/:id',
            element: <RevenueDetailsScreen />
        },
        {
            path: '/suppliers',
            element: <SuppliersDataView />
        },
        {
            path: '/suppliers/create',
            element: <AddSupplierPage />
        },
        {
            path: '/supplier/edit/:id',
            element: <EditSupplierPage />
        },
        {
            path: '/supplier/details/:id',
            element: <SupplierDetailsPage />
        },
        {
            path: '/finance/balance',
            element: <BalanceDataView />
        }
    ]
};

export default AdminRoutes;
