import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MenuItem, CircularProgress, Typography, FormHelperText, FormControl } from '@mui/material';
import { useTheme } from '@mui/styles';

const DateRangeSelector = ({
    options,
    loading = false,
    selectedRange,
    setSelectedRange,
    error,
    helperText,
    disabled,
    filterText = 'Select Range'
}) => {
    const theme = useTheme();

    const getOptionLabel = (option) => {
        if (typeof option === 'object') {
            return option.name || '';
        }
        const range = options.find((item) => item.id === option);
        return range ? range.name : '';
    };

    return (
        <FormControl
            fullWidth
            error={Boolean(error)}
            sx={{
                margin: 0,
                padding: 0
            }}
        >
            <Autocomplete
                disablePortal
                id="date-range-selector"
                loading={loading}
                disabled={disabled}
                options={options}
                value={selectedRange || null}
                getOptionLabel={getOptionLabel}
                onChange={(event, newValue) => setSelectedRange(newValue)}
                sx={{
                    margin: 0,
                    padding: 0
                }}
                renderInput={(params) => (
                    <>
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: '6px',
                                color: theme.palette.grey[500]
                            }}
                        >
                            {filterText}
                        </Typography>
                        <TextField
                            {...params}
                            fullWidth
                            placeholder={!selectedRange ? filterText : ''}
                            error={Boolean(error)}
                            sx={{
                                paddingBottom: '4px', // Reduce space below input
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '4px',
                                    backgroundColor: theme.palette.background.default,
                                    borderWidth: '2px',
                                    '&:hover': {
                                        '& fieldset': {
                                            borderColor: theme.palette.secondary.main
                                        }
                                    },
                                    '&.Mui-focused': {
                                        '& fieldset': {
                                            borderColor: theme.palette.secondary.main
                                        }
                                    }
                                },
                                '& .MuiOutlinedInput-input': {
                                    backgroundColor: theme.palette.background.default,
                                    borderRadius: '0px',
                                    padding: '3px !important', // Adjust padding inside input
                                    fontFamily: theme.typography.h6,
                                    color: theme.palette.grey[500]
                                },
                                '& .MuiOutlinedInput-root fieldset': {
                                    borderWidth: '0.5px',
                                    borderColor: theme.palette.grey[200],
                                    borderRadius: '4px',
                                    boxShadow: '2px 2px 2px -1px rgba(209,209,209,0.75)'
                                },
                                '& input::placeholder': {
                                    fontFamily: theme.typography.placeholderText
                                },
                                '& input:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 100px white inset',
                                    WebkitTextFillColor: theme.palette.grey[500],
                                    fontFamily: theme.typography.h6
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    </>
                )}
                renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id}>
                        {option.name}
                    </MenuItem>
                )}
            />
        </FormControl>
    );
};

export default DateRangeSelector;
