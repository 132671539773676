import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import nodata from '../../assets/images/nodata.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NoDataCard = ({ title, description, balance, balanceText }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const currentUser = useSelector((state) => state.me.getMe);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" direction="column">
                <Grid sx={{ width: 350, height: 350, textAlign: 'center' }} alignItems="center" justifyContent="center" container>
                    <img src={nodata} alt="nodata" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </Grid>
                {balance ? (
                    <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: 16 }}>
                        {balanceText ? t('finance.thirdSubmenu.no_data') : `${t('no_data.title')} ${balance} ${t('no_data.yet')}.`}
                    </Typography>
                ) : (
                    <Grid>
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: 16 }}>
                            {t('no_data.title')} {title} {t('no_data.yet')}.
                        </Typography>

                        {currentUser?.data?.role !== 'DOCTOR' ? (
                            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 300, color: theme.palette.grey[600], mt: 1 }}>
                                {t('no_data.utilize')} {description} {t('no_data.located')} {title}.
                            </Typography>
                        ) : (
                            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 300, color: theme.palette.grey[600], mt: 1 }}>
                                {t('no_data.currently')} {title} {t('no_data.listed')}
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default NoDataCard;
