import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const revenueSlice = createSlice({
    name: 'revenue',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState }
    },
    reducers: {
        getAllRevenueStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllRevenueSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllRevenueFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllRevenueReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneRevenueStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneRevenueSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneRevenueFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneRevenueReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        }
    }
});

export const {
    getAllRevenueStart,
    getAllRevenueSuccess,
    getAllRevenueFail,
    getAllRevenueReset,
    getOneRevenueStart,
    getOneRevenueSuccess,
    getOneRevenueFail,
    getOneRevenueReset
} = revenueSlice.actions;

export const getAllRevenue = (data) => async (dispatch) => {
    try {
        dispatch(getAllRevenueStart());
        const response = await APIClient.get('/revenue', {
            params: {
                limit: data.limit,
                page: data.page,
                ...(data.filters && { filters: data.filters }),
                doctorId: data.doctorId,
                startDate: data.startDate,
                endDate: data.endDate,
                search: data.search,
                language: data.language
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllRevenueSuccess(response.data));
        } else {
            dispatch(getAllRevenueFail(t('alerts.revenueFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllRevenueFail);
    }
};

export const getOneRevenue = (revenueId) => async (dispatch) => {
    try {
        dispatch(getOneRevenueStart());
        const response = await APIClient.get(`/revenue/${revenueId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneRevenueSuccess(response.data));
        } else {
            dispatch(getOneRevenueFail(t('alerts.revenueFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneRevenueFail);
    }
};

export const clearOneRevenue = () => async (dispatch) => {
    dispatch(getOneRevenueReset());
};
export const clearAllRevenues = () => async (dispatch) => {
    dispatch(getAllRevenueReset());
};

export default revenueSlice.reducer;
