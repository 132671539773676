import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'store/constant';
import { setMenu } from 'store/customization';
import Breadcrumbs from 'components/extended/PageTitleComponent';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            paddingTop: '100px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            paddingTop: '100px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const MainLayout = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const handleLeftDrawerToggle = () => {
        dispatch(setMenu(!leftDrawerOpened));
    };

    useEffect(() => {
        dispatch(setMenu(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const displaySearch =
        location.pathname === '/clinics' ||
        location.pathname === '/clinic-types' ||
        location.pathname === '/admins' ||
        location.pathname === '/doctors' ||
        location.pathname === '/appointments' ||
        location.pathname === '/specializations' ||
        location.pathname === '/patients' ||
        location.pathname === '/finance/revenue' ||
        location.pathname === '/finance/expenses' ||
        location.pathname === '/suppliers' ||
        location.pathname === '/services';

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar sx={{ backgroundColor: theme.palette.grey[50], padding: '0px !important' }}>
                    <Header
                        handleLeftDrawerToggle={handleLeftDrawerToggle}
                        leftDrawerOpened={leftDrawerOpened}
                        displaySearch={displaySearch}
                    />
                </Toolbar>
            </AppBar>
            <Sidebar drawerOpen={leftDrawerOpened || leftDrawerOpened?.opened} drawerToggle={handleLeftDrawerToggle} />
            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
