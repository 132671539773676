import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Paper, Box } from '@mui/material';

const SpecializationLoadingSkeleton = () => {
    const numberOfRows = 7;

    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    p: 2,
                    mb: 3,
                    mt: 3
                }}
            >
                <Box mb={3}>
                    <Skeleton variant="text" width="15%" animation="wave" />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 3
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 4,
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" animation="wave" width="60%" height="20%" />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" animation="wave" width="100%" height="20%" style={{ marginBottom: '8px' }} />
                        <Skeleton variant="text" animation="wave" width="100%" height="20%" style={{ marginBottom: '8px' }} />
                        <Skeleton variant="text" animation="wave" width="100%" height="20%" style={{ marginBottom: '8px' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SpecializationLoadingSkeleton;
