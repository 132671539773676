import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMe } from 'store/me';

const MenuList = () => {
    const token = localStorage.getItem('patient-menagment-token');
    const [currentUserData, setCurrentUserData] = useState({});
    const currentUser = useSelector((state) => state.me.getMe);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (currentUser) {
            switch (true) {
                case currentUser.loading:
                    setLoading(true);
                    break;
                case currentUser.success:
                    setCurrentUserData(currentUser.data);
                    setLoading(false);
                    break;
                case currentUser.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [currentUser]);

    let decoded;
    let role;

    if (token) {
        decoded = jwtDecode(token);
        role = decoded.role;
    }

    let navItems;

    if (role === 'ADMIN' && !loading) {
        navItems = menuItem.admin
            .map((group) => {
                if (group.children) {
                    const filteredChildren = group.children.filter((child) => {
                        if (
                            currentUserData?.clinic?.isFinanceViewAllowed === false &&
                            (child.id === 'finance' || child.url === '/suppliers')
                        ) {
                            return false; // Exclude this child
                        }
                        return true; // Include this child
                    });

                    return { ...group, children: filteredChildren };
                }

                return group;
            })
            .map((group) => {
                switch (group.type) {
                    case 'group':
                        return <NavGroup key={group.id} item={group} />;
                    default:
                        return (
                            <Typography key={group.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            })
            .filter(Boolean);
    }

    if (role === 'SUPERADMIN') {
        navItems = menuItem.superadmin.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }
    if (role === 'DOCTOR') {
        navItems = menuItem.doctor.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }

    return <>{navItems}</>;
};

export default MenuList;
